import './App.scss'
import { lazy, useMemo, Suspense } from 'react'
import { retry } from './utils/CommonFunctions'
import { createTheme, ThemeProvider } from '@mui/material'
import ScrollToTop from './components/ScrollToTop'
import Loader from './components/Loader'
import { Routes, Route, Navigate, HashRouter } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

const Frameworks = lazy(() =>
  retry(() => import('./pages/web-framework/Frameworks')),
)
const Login = lazy(() => retry(() => import('./pages/Login')))
const CreateFramework = lazy(() =>
  retry(() => import('./pages/web-framework/CreateFramework')),
)
const FrameworkDetails = lazy(() =>
  retry(() => import('./pages/web-framework/FrameworkDetails')),
)
const UpdateFramework = lazy(() =>
  retry(() => import('./pages/web-framework/UpdateFramework')),
)
const Sections = lazy(() =>
  retry(() => import('./pages/web-framework/Sections')),
)

const App = () => {
  const { user } = useAuthContext()

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          background: {
            default: '#f4f7ff',
          },
          primary: {
            main: '#A83811',
          },
          secondary: {
            main: '#FBE5DB',
          },
        },
        typography: {
          fontFamily: 'Outfit, sans-serif',
          caption: {
            fontSize: '0.8rem',
            color: '#bbbbbb',
            fontWeight: 500,
          },
          h3: {
            fontFamily: 'Unaa, serif',
            fontWeight: 700,
          },
          h4: {
            fontFamily: 'Unaa, serif',
            fontWeight: 700,
            color: '#A83811',
          },
          body1: {
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
          },
          body2: {
            fontFamily: 'Unaa, serif',
            fontWeight: 700,
          },
          subtitle1: {
            color: '#BBBBBB',
            fontSize: '1rem',
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: '#FFF',
                color: '#000',
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                padding: '0.75rem 1rem',
                fontSize: '1rem',
                borderRadius: '10.6667px',
                fontWeight: 'bold',
                textTransform: 'unset',
                '&:hover': {
                  backgroundColor: '#AA4625',
                  color: '#FFF',
                },
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: '#FFF6F3',
                color: '#A83811 !important',
                border: '1px solid #FFF6F3',
                borderRadius: '10.6667px !important',
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: '#FFF6F2',
                color: '#000',
                borderRadius: '0 11.6287px 11.6287px 0',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                verticalAlign: 'top',
                fontFamily: 'Outfit, sans-serif',
              },
              stickyHeader: {
                top: '0',
                left: '0',
                zIndex: '2',
                position: 'sticky',
                backgroundColor: '#FFF',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                background: '#FFFFFF',
                boxShadow:
                  '0px 5.81433px 11.6287px rgba(0, 0, 0, 0.04), 0px 0px 2.90717px rgba(0, 0, 0, 0.06), 0px 0px 1.45358px rgba(0, 0, 0, 0.04)',
                border: 0,
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#FFF',
                borderRadius: 0,
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              root: {
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 'normal',
              },
            },
          },
          // MuiChip: {
          //   styleOverrides: {
          //     deleteIcon: {
          //       display: 'none',
          //     },
          //   },
          // },
        },
      }),
    [],
  )

  return (
    <HashRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/admin"
              element={user ? <Frameworks /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="/admin/login"
              element={!user ? <Login /> : <Navigate to="/admin" />}
            />
            <Route
              path="/admin/web-framework/create-web-framework"
              element={
                user ? <CreateFramework /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="/admin/web-frameworks/:name"
              element={
                user ? <FrameworkDetails /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="/admin/web-framework/update-web-framework/:id"
              element={
                user ? <UpdateFramework /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="/admin/web-framework/sections"
              element={user ? <Sections /> : <Navigate to="/admin/login" />}
            />
            <Route path="/" element={<Navigate to="/admin" />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </HashRouter>
  )
}

export default App
