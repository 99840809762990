import { Stack, Typography } from '@mui/material'
import React from 'react'
import logo from '../resources/logo.svg'

const Loader = () => {
  return (
    <Stack
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={logo} alt="logo" width="150" />
      <Typography sx={{ mt: 2 }}>Loading...</Typography>
    </Stack>
  )
}

export default Loader
